import React from 'react'
import { graphql, withPrefix } from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'

import * as styles from '../styles/article.module.sass'

const Page = ({ data }) => {
  const page = data.wpPage
  const originalPage = page.translations?.find((t) => t.language.locale === 'en_US')
  const currentUri = originalPage?.isFrontPage ? page.uri.replace(`/${page.slug}`, '') : page.uri
  const content = page.content.replace(/href="\/(.*?)"/g, (m, $1) => 'href="' + withPrefix($1) + '"')

  const floatContentGallery = () => {
    let galleries = document.querySelectorAll('.content-gallery')
    // console.log(galleries)
  }

  return (
    <Layout currentUri={currentUri} isFrontPage={page.isFrontPage} currentLang={page.language.locale} translations={page.translations} articleType="page">
      <Seo title={page.title} lang={page.language.locale.slice(0, 2)} isFrontPage={page.isFrontPage} />
      <article className={styles.article} dangerouslySetInnerHTML={{ __html: content }} />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ($databaseId: Int!) {
    wpPage(databaseId: { eq: $databaseId }) {
      title
      content
      uri
      slug
      isFrontPage
      language {
        locale
      }
      translations {
        uri
        slug
        isFrontPage
        language {
          locale
        }
      }
    }
  }
`
